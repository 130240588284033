import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getProduct } from "services/productsService";
import ProductInfoCard from "components/Cards/ProductInfoCard";
import { mediaURL } from "variables/baseURL";

const ProductInfo = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState([]);

  const getProductInfo = async (id) => {
    const res = await getProduct(id);
    setProduct(res.data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getProductInfo(productId);
  }, [productId]);

  return (
    <div className="bg-white py-4">
      <div className="container sm:w-full py-4 px-8">
        <div
          onClick={() => navigate(-1)}
          className="flex items-center mb-8 cursor-pointer"
        >
          <div className="h-4 w-4 icon-left mx-2" />
          <span className="text-darkGrey fs-5">Назад</span>
        </div>

        <div className="flex lg:flex-col">
          <div className="md:w-full w-30">
            <div className="bordered">
              <img
                className="w-full"
                src={`${mediaURL}${product?.photo}`}
                alt={product?.id}
              />
            </div>
          </div>

          <div className="w-70 lg:w-full md:w-full md:w-full h-full flex flex-col md:px-0 px-8">
            <p className="text-darkGrey fs-8 fw-600 md:my-4 mb-4">
              {product?.name}
            </p>

            <p className="whitespace-pre text-darkGrey fs-18px">
              {product?.description}
            </p>

            <p className="fs-8 lg:fs-5 text-orange my-4">
              ОСНОВНЫЕ ТЕХНИЧЕСКИЕ ХАРАКТЕРИСТИКИ
            </p>

            <p className="whitespace-pre text-darkGrey fs-18px">
              {product?.characteristics}
            </p>
          </div>
        </div>
      </div>
      <ProductInfoCard product={product && product} />
    </div>
  );
};

export default ProductInfo;
