import axios from "axios";
import { baseURL } from "variables/baseURL";

export const getCertificates = () => {
  const response = axios.get(baseURL + "certificate/");
  return response;
};

export const getAddress = () => {
  const response = axios.get(baseURL + "address/");
  return response;
};

export const sendMessage = (message) => {
  const response = axios.post(baseURL + "message/", message);
  return response;
};
