import diagram from "assets/icons/diagramma.png";

const Diagram = () => {
  return (
    <div className="flex flex-col justify-center items-center my-16 bg-white">
      <img className="w-50 lg:w-70 my-16" src={diagram} alt="" />

      <div className="my-16">
        <button
          onClick={() => window.open("https://askug.sarf.uz/")}
          className="bg-orange text-white px-8 fs-5 lg:fs-4 py-4"
        >
          Перейти в систему АСКУГ
        </button>
      </div>
    </div>
  );
};

export default Diagram;
