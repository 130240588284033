import { Swiper } from "swiper/react";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

const CustomSwiper = ({
  children,
  slidesPerView,
  modules,
  pagination,
  freeMode,
}) => {
  return (
    <Swiper
      effect="fade"
      pagination={pagination}
      freeMode={freeMode}
      breakpoints={{
        375: {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
          slidesPerGroup: 2,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
          slidesPerGroup: 3,
        },
      }}
      slidesPerView={slidesPerView}
      lazy={true}
      loopFillGroupWithBlank={true}
      navigation={true}
      modules={modules}
    >
      {children}
    </Swiper>
  );
};

export default CustomSwiper;
