import { useState, useEffect } from "react";
import { getAddress, sendMessage } from "services/contactsService";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import locationIcon from "assets/icons/location.svg";
import close from "assets/icons/close.svg";
import { toast } from "react-toastify";

const Footer = () => {
  const [message, setMessage] = useState({ name: "", email: "", text: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [address, setAddress] = useState("");

  const defaultState = {
    center: [41.311158, 69.279737],
    zoom: 12,
  };

  const getAddresses = async () => {
    try {
      const res = await getAddress();
      setIsLoading(false);
      setAddress(res.data);
    } catch (error) {
      setIsLoading(true);
    }
  };

  const handleSendMessage = async () => {
    try {
      const { status } = await sendMessage(message);
      setMessage({ name: "", email: "", text: "" });
      toast.success("Xabar yuborildi");
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getAddresses();
  }, []);

  return (
    <>
      {isOpen && (
        <div className="w-full h-full absolute top-0 left-0 bg-black-08 zIndex-10">
          <div className="fixed centered bg-white h-70vh md:w-4/5 w-60 shadow-md rounded-md">
            <YMaps className="rounded-md">
              <Map
                className="w-full h-full rounded-md"
                defaultState={defaultState}
              >
                {address?.map((add) => (
                  <Placemark
                    key={add?.id}
                    geometry={[add?.latitude, add?.longitude]}
                  />
                ))}
              </Map>
            </YMaps>
            <img
              src={close}
              className="w-8 h-8 fixed top-4 right-4 bg-black p-1 rounded-md"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="bg-darkGray h-20rem flex justify-center items-center">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center p-4">
          <div
            onClick={() => window.scrollTo(0, 0)}
            className="flex flex-col items-center justify-center icon-div cursor-pointer my-2"
          >
            <span className="h-4 w-4 m-4 icon-top outline-none" />
            <p className="text-darkGrey fs-5">Вверх</p>
          </div>

          <div className="flex lg:flex-col justify-center container-80 my-4">
            <div className="flex flex-col md:text-center text-right w-50 my-8 lg:w-full">
              <span className="text-darkGrey mb-3">Позвоните нам</span>
              <p className="text-darkGrey fs-5 fw-600 mb-3 lg:mb-4">
                {address[0]?.phone_number1}
              </p>
              <p className="text-darkGrey fs-5 fw-600 mb-3 lg:mb-4">
                {address[0]?.phone_number2}
              </p>
              <p className="text-darkGrey fs-5 fw-600 mb-3 lg:mb-8">
                {address[0]?.phone_number3}
              </p>
              <span className="text-darkGrey mb-2">Адрес</span>
              <p className="text-darkGrey fs-5 fw-600 mb-8">
                {address[0]?.address}
              </p>
              <span className="text-darkGrey mb-2">E-mail</span>
              <p className="text-darkGrey fs-5 fw-600">{address[0]?.email}</p>
            </div>

            <div className="lg:hidden hr-vertical" />

            <div className="flex flex-col justify-center md:w-full w-50 my-8">
              <input
                className="md:w-full w-60 text-darkGrey fs-4"
                type="text"
                placeholder="Имя"
                value={message.name}
                onChange={(e) =>
                  setMessage({ ...message, name: e.target.value })
                }
              />
              <input
                className="md:w-full w-60 text-darkGrey fs-4"
                type="text"
                placeholder="E-mail"
                value={message.email}
                onChange={(e) =>
                  setMessage({ ...message, email: e.target.value })
                }
              />
              <label className="text-darkGrey">Сообщение</label>
              <textarea
                className="md:w-full w-60 text-darkGrey fs-4"
                cols="30"
                rows="2"
                value={message.text}
                onChange={(e) =>
                  setMessage({ ...message, text: e.target.value })
                }
              />
              <button
                className="header-button my-4 w-10rem md:mx-auto"
                onClick={() =>
                  message.email &&
                  message.name &&
                  message.text &&
                  handleSendMessage()
                }
              >
                Отправить
              </button>
            </div>
          </div>

          <div
            className="flex flex-col justify-center items-center my-4 cursor-pointer gap-4 mb-12"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <img src={locationIcon} alt="location" />
            <p className="text-darkGrey">На карте</p>
          </div>

          <p className="text-darkGrey">© iMeter 2020</p>
        </div>
      )}
    </>
  );
};

export default Footer;
