import { motion } from "framer-motion";

const Information = () => {
  const fadeUp = {
    hidden: {
      y: 150,
      opacity: 0,
    },
    visible: () => ({
      y: 0,
      opacity: 1,
    }),
  };

  return (
    <div className="flex flex-col gap-4 bg-white">
      <motion.div className="h-60vh w-full flex lg:justify-center items-center relative my-12">
        <div className="flex flex-col justify-center items-center w-50 lg:w-80 h-auto bg-orange p-16 zIndex-10">
          <motion.p
            className="mb-4 text-white fs-5"
            transition={{ delay: 0, duration: 1 }}
            initial="hidden"
            whileInView="visible"
            variants={fadeUp}
          >
            Компания iMeter создана в 2019 году и специализируется на
            производстве высокоточных и технологически сложных средств учета
            природного газа под маркой SARF.
          </motion.p>
          <motion.p
            className="text-white fs-5"
            transition={{ delay: 0.3, duration: 1 }}
            initial="hidden"
            whileInView="visible"
            variants={fadeUp}
          >
            На сегодняшний день мы способны производить до 1 млн. ультразвуковых
            счетчиков газа с прогрессирующим ежемесячным приростом до 100 тыс.
            единиц.
          </motion.p>
        </div>

        <div className="w-80 lg:w-full h-full absolute top-0 right-0 bg-buildingImg" />
      </motion.div>

      <motion.div className="h-60vh w-full flex lg:justify-center justify-end items-center relative my-12">
        <div className="flex flex-col justify-center items-center w-50 lg:w-80 h-60 bg-orange p-16 zIndex-1">
          <motion.p
            className="mb-4 text-white fs-5"
            transition={{ delay: 0, duration: 1 }}
            initial="hidden"
            whileInView="visible"
            variants={fadeUp}
          >
            Компания iMeter создана в 2019 году и специализируется на
            производстве высокоточных и технологически сложных средств учета
            природного газа под маркой SARF.
          </motion.p>
          <motion.p
            className="text-white fs-5"
            transition={{ delay: 0.3, duration: 1 }}
            initial="hidden"
            whileInView="visible"
            variants={fadeUp}
          >
            На сегодняшний день мы способны производить до 1 млн. ультразвуковых
            счетчиков газа с прогрессирующим ежемесячным приростом до 100 тыс.
            единиц.
          </motion.p>
        </div>

        <div className="w-80 lg:w-full h-full absolute top-0 left-0 bg-boyImg" />
      </motion.div>
    </div>
  );
};

export default Information;
