import { Link } from "react-router-dom";
import logo from "assets/logo/Logo.png";
import { motion } from "framer-motion";
import { useEffect } from "react";

const Header = () => {
  useEffect(() => {
    document.title = "ВОТ ЭТО – УЧЕТ!";
  }, []);

  return (
    <div className="flex flex-col items-center justify-center sm:my-12">
      <motion.img
        className="w-25rem md:hidden w-20rem my-4"
        src={logo}
        alt="logo"
        initial={{ y: 90, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      />

      <motion.p
        className="text-darkGrey md:my-0 my-4 bold text-center p-4 md:fs-2rem fs-4_5rem"
        initial={{ y: 90, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        ВОТ ЭТО – УЧЕТ!
      </motion.p>
      <motion.p
        className="text-darkGrey md:my-0 my-4 lg:fs-5 max-w-36rem text-center p-4"
        initial={{ y: 90, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.9, duration: 1.2 }}
      >
        Компания iMeter создана в 2019 году и специализируется на производстве
        высокоточных и технологически сложных средств учета природного газа под
        маркой SARF.
      </motion.p>
      <motion.div
        className="flex gap-8 items-center justify-center relative w-full my-4 mb-12"
        initial={{ y: 90, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1.2, duration: 1.5 }}
      >
        <Link
          to="/products"
          className="header-button outline-none w-5rem border-none"
        >
          Продукция
        </Link>
        <Link
          to="/price-list"
          className="header-button outline-none w-5rem border-none"
        >
          Прайс-лист
        </Link>
      </motion.div>
    </div>
  );
};

export default Header;
