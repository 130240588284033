const PriceCard = ({ counter }) => {
  return (
    <div className="my-12">
      <div className="flex items-center justify-center">
        <div className="flex w-50 lg:w-60 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {counter.name}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 lg:w-40 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {counter.price}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex lg:w-60 justify-end">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            {counter.description}
          </span>
        </div>

        <div className="w-100px mx-12" />

        <div className="w-50 lg:w-40 flex my-4">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Цена за единицу
          </span>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
