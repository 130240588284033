import { motion } from "framer-motion";
import { useEffect } from "react";

const Header = () => {
  const fadeUp = {
    hidden: {
      y: 150,
      opacity: 0,
    },
    visible: () => ({
      y: 0,
      opacity: 1,
    }),
  };

  useEffect(() => {
    document.title = "Прайс-лист";
  }, []);

  return (
    <div className="flex flex-col items-center justify-center text-center my-8">
      <motion.p
        className="text-darkGrey fw-600 fs-5rem lg:fs-3rem mb-8 p-4"
        transition={{ delay: 0, duration: 1 }}
        initial="hidden"
        whileInView="visible"
        variants={fadeUp}
      >
        Прайс-лист
      </motion.p>

      <motion.p
        className="text-darkGrey fs-5 lg:fs-2rem w-40rem sm:w-90 lg:w-70 mb-16 p-4"
        transition={{ delay: 0.5, duration: 1 }}
        initial="hidden"
        whileInView="visible"
        variants={fadeUp}
      >
        Узнайте цены на все модификации наших счетчиков
      </motion.p>
    </div>
  );
};

export default Header;
