import { motion } from "framer-motion";

import g6 from "assets/products/1619377500_74.png";
import advantages from "assets/icons/advantages.svg";

const fadeUp = {
  hidden: {
    y: 150,
    opacity: 0,
  },
  visible: () => ({
    y: 0,
    opacity: 1,
  }),
};

const Benefits = () => {
  return (
    <div
      className="h-90vh lg:h-60vh bg-white"
      transition={{ delay: 0, duration: 1.5 }}
      initial="hidden"
      whileInView="visible"
      variants={fadeUp}
    >
      <motion.div
        className="h-10 md:h-30 flex justify-center items-center"
        transition={{ delay: 0.3, duration: 1.5 }}
        whileInView="visible"
        initial="hidden"
        variants={fadeUp}
      >
        <p className="text-darkGrey fs-6 fw-600">ПРЕИМУЩЕСТВА</p>
      </motion.div>

      <motion.div
        className="w-full h-90 md:h-70 relative flex justify-center items-center p-8"
        transition={{ delay: 0.5, duration: 1.5 }}
        whileInView="visible"
        initial="hidden"
        variants={fadeUp}
      >
        <img
          className="centered w-60 lg:w-full p-8"
          src={advantages}
          alt="advantages"
        />
        <img className="centered-img w-40 p-2" src={g6} alt="counter_g6" />
      </motion.div>
    </div>
  );
};

export default Benefits;
