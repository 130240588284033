import Header from "./Header";
import Counters from "./Counters";
import Strengths from "components/Strengths/Strengths";
import Benefits from "./Benefits";
import Footer from "components/Footer/Footer";

const Main = () => {
  return (
    <>
      <Header />
      <Strengths />
      <Counters />
      <Benefits />
      <Footer />
    </>
  );
};

export default Main;
