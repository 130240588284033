import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { pages } from "variables/pages";

import burger from "assets/icons/Burger.svg";
import logo from "assets/logo/Logo.png";

const activeStyle = {
  color: "#f37021",
  fontWeight: 500,
};

const Navbar = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <nav className="navbar relative py-4">
      <div className="mx-4">
        <NavLink to="/main">
          <img src={logo} alt="logo" className="w-90px" />
        </NavLink>
      </div>

      <div className="menu-icon py-2 cursor-pointer" onClick={handleClick}>
        <img className="w-30px" src={burger} alt="" />
      </div>

      <ul className={active ? "nav-menu active" : "nav-menu"}>
        {pages.map((page) => (
          <li key={page.id} className="p-2" onClick={handleClick}>
            <NavLink
              to={page.path}
              style={({ isActive }) => (isActive ? activeStyle : null)}
            >
              {page.label}
            </NavLink>
          </li>
        ))}

        <li
          className="p-2"
          onClick={() => window.scrollTo(0, document.body.scrollHeight)}
        >
          <span className="fw-500 cursor-pointer text-darkGrey">Контакты</span>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
