import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
import { loadTrianglesPreset } from "tsparticles-preset-triangles";
import particlesConfig from "./particles-config";

function ParticlesBackground() {
  const particlesInit = async (main) => {
    await loadTrianglesPreset(main);
  };

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={particlesConfig}
    />
  );
}

export default ParticlesBackground;
