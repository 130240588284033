const particlesConfig = {
  fullScreen: {
    enable: true,
    zIndex: -10,
  },
  particles: {
    collisions: {
      enable: false,
    },
    number: {
      value: 90, // Adjust the number of particles
      density: {
        enable: true,
        value_area: 900,
      },
    },
    color: {
      value: "#ffffff", // Adjust the color of the particles
    },
    shape: {
      type: "triangle", // Use triangles as the particle shape
      stroke: {
        width: 10,
        color: "#000000",
      },
      polygon: {
        nb_sides: 5, // Number of sides for the triangles
      },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        enable: true,
        speed: 40,
        size_min: 0.5,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 200,
      color: "#fff",
      opacity: 0.3,
      width: 2,
    },
    move: {
      enable: true,
      speed: 3,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: true,
        mode: "repulse",
      },
      onclick: {
        enable: true,
        mode: "push",
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
  background: {
    color: {
      value: "#d0d0d0",
    },
  },

  // fullScreen: {
  //   enable: true,
  //   zIndex: -2,
  // },
  // particles: {
  //   collisions: {
  //     enable: false,
  //   },
  //   move: {
  //     direction: "none",
  //     enable: true,
  //     outModes: {
  //       default: "bounce",
  //     },
  //     random: true,
  //     speed: 3,
  //     straight: true,
  //   },
  //   opacity: {
  //     value: 0.9,
  //   },
  // },
  // preset: "triangles",
  // detectRetina: false,
  // fpsLimit: 30,
  // background: {
  //   color: {
  //     value: "#c4c4c4",
  //   },
  // },
};

export default particlesConfig;

/* particles-config.js */

// Load the triangles preset
// tsParticles.load("tsparticles", {
//   preset: "triangles",
// });
