import building from "assets/backgrounds/building_2.jpg";

const Services = ({ isLoading, cooperation }) => {
  return (
    <>
      {isLoading ? (
        <div className="bg-darkGray h-20rem flex justify-center items-center">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="flex lg:justify-center justify-end relative bg-white">
          <div className="bg-orange lg:w-80 lg:relative absolute top-5 left-0 w-60 px-24 lg:px-4 py-16 lg:my-8 my-12">
            {cooperation?.contents?.map(
              (text, id) =>
                id > 0 && (
                  <div
                    key={id}
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  ></div>
                )
            )}
          </div>
          <img className="lg:hidden w-50 mb-20" src={building} alt="building" />
        </div>
      )}
    </>
  );
};

export default Services;
