import { useState, useEffect } from "react";
import CounterCard from "components/Cards/CounterCard";
import { getProducts } from "services/productsService";
import { mediaURL } from "variables/baseURL";

const AllProducts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const getAllProducts = async () => {
    const res = await getProducts();
    setIsLoading(false);
    setProducts(res.data);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-darkGray h-20rem flex justify-center items-center">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="flex flex-col gap-8 py-16 my-12 bg-white">
          {products.map((product) => (
            <CounterCard
              key={product?.id}
              id={product?.id}
              img={`${mediaURL}${product?.photo}`}
              name={product.name}
              description={product.description}
              price={product.price}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default AllProducts;
