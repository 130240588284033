import { motion } from "framer-motion";
import { useEffect } from "react";

const Header = () => {
  const fadeUp = {
    hidden: {
      y: 150,
      opacity: 0,
    },
    visible: () => ({
      y: 0,
      opacity: 1,
    }),
  };

  useEffect(() => {
    document.title = "О компании iMeter";
  }, []);

  return (
    <div className="flex items-center justify-center text-center my-8">
      <motion.p
        className="fs-5rem text-darkGrey fw-600 lg:fs-3rem w-40rem mb-16 p-4"
        transition={{ delay: 0, duration: 1 }}
        initial="hidden"
        whileInView="visible"
        variants={fadeUp}
      >
        О компании iMeter
      </motion.p>
    </div>
  );
};

export default Header;
