import { Swiper } from "swiper/react";
import { Lazy, Navigation, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/navigation";

const CountersSwiper = ({ children, setactiveIndex }) => {
  return (
    <Swiper
      autoplay={{
        delay: 15000,
        disableOnInteraction: false,
      }}
      slidesPerView={1}
      lazy={true}
      loop={true}
      loopFillGroupWithBlank={true}
      navigation={true}
      modules={[Lazy, Navigation, Autoplay]}
      onSlideChange={(e) => setactiveIndex(e.realIndex)}
    >
      {children}
    </Swiper>
  );
};

export default CountersSwiper;
