import { useNavigate } from "react-router-dom";

const CounterCard = ({ id, img, name, description, price }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex justify-center items-center hover:shadow-lg p-4 container lg:container-90 rounded-md">
      <div className="w-40 flex justify-center items-center">
        <img className="w-70" src={img} alt="name" />
      </div>
      <div className="w-60">
        <p className="text-darkGrey fs-24px fw-600 my-4">{name}</p>
        <p className="whitespace-pre text-darkGrey fs-15px lg:fs-4">
          {description}
        </p>
        <p className="text-orange fs-8 fw-600 my-4">{price}</p>

        <button
          className="bg-darkGrey hover:bg-orange fs-5 text-white outline-none px-8 py-2 my-2 border-none rounded-sm"
          onClick={() => navigate(`/products/${id}`)}
        >
          Подробнее
        </button>
      </div>
    </div>
  );
};

export default CounterCard;
