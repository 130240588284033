import React from "react";

import quality from "assets/icons/quality.svg";
import like from "assets/icons/like.svg";
import techSupport from "assets/icons/techSupport.svg";
import { motion } from "framer-motion";

const Opening = {
  hidden: {
    y: 250,
    opacity: 0,
  },
  visible: () => ({
    y: 0,
    opacity: 1,
  }),
};

const Strengths = () => {
  return (
    <div data-aos="fade-down" className="bg-white">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-center text-darkGrey m-20">НАШИ СИЛЬНЫЕ СТОРОНЫ</h1>

        <div className="flex md:flex-col justify-center items-center gap-4 my-4 mb-20 container">
          <motion.div
            className="flex flex-col justify-center items-center w-4/12 lg:h-20rem lg:w-full p-4 lg:shadow"
            initial="hidden"
            transition={{ delay: 1 * 0.5, duration: 1.5 }}
            whileInView="visible"
            variants={Opening}
          >
            <img src={quality} alt="quality" />
            <p className="text-darkGrey bold my-4 fs-5 text-center">
              100% гарантия качества
            </p>
            <p className="text-darkGrey text-center my-4">
              Мы производим счетчики из надежных комплектующих
            </p>
          </motion.div>

          <motion.div
            className="flex flex-col justify-center items-center w-4/12 lg:h-20rem lg:w-full lg:shadow"
            initial="hidden"
            transition={{ delay: 1 * 1, duration: 1.5 }}
            whileInView="visible"
            variants={Opening}
          >
            <img src={like} alt="quality" />
            <p className="text-darkGrey bold my-4 fs-5 text-center">
              Доступные цены
            </p>
            <p className="text-darkGrey text-center my-4">
              Мы продаем свою продукцию по разумным ценам.
            </p>
          </motion.div>

          <motion.div
            className="flex flex-col justify-center items-center w-4/12 lg:h-20rem lg:w-full p-4 lg:shadow"
            initial="hidden"
            transition={{ delay: 1 * 1.5, duration: 1.5 }}
            whileInView="visible"
            variants={Opening}
          >
            <img src={techSupport} alt="quality" />
            <p className="text-darkGrey bold my-4 fs-5 text-center">
              Техническая поддержка
            </p>
            <p className="text-darkGrey text-center my-4">
              Бесплатная консультация опытных специалистов iMeter.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Strengths;
