import React from "react";
import Header from "./Header";
import Strengths from "components/Strengths/Strengths";
import Information from "./Information";
import Footer from "components/Footer/Footer";
import Certificates from "./Certificates";

const About = () => {
  return (
    <>
      <Header />
      <Strengths />
      <Information />
      <Certificates />
      <Footer />
    </>
  );
};

export default About;
