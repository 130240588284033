import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import AllProducts from "./AllProducts";
import ProductInfo from "./ProductInfo";
import Footer from "components/Footer/Footer";

const Products = () => {
  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<AllProducts />} />
        <Route path=":productId" element={<ProductInfo />} />
      </Routes>

      <Footer />
    </>
  );
};

export default Products;
