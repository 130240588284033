const DocsCard = ({ text, file }) => {
  return (
    <div className="w-4/12 md:w-full lg:w-30 flex flex-col justify-between items-center px-8 py-4 m-2 hover:shadow-lg lg:shadow rounded-md">
      <p className="w-full text-darkGrey fs-5 lg:fs-4 text-center my-12">
        {text}
      </p>
      <button
        onClick={() => window.open(file)}
        className="bg-orange text-white fs-5 lg:fs-4 px-8 py-2 mb-12"
      >
        Документация
      </button>
    </div>
  );
};

export default DocsCard;
