import { useState, useEffect } from "react";
import Strengths from "components/Strengths/Strengths";
import Footer from "components/Footer/Footer";
import Header from "./Header";
import Services from "./Services";
import { getDescription } from "services/cooperateService";

const Cooperation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [cooperation, setCooperation] = useState([]);

  const getCooperations = async () => {
    const { data } = await getDescription();
    setIsLoading(false);
    setCooperation({
      name: data[0]?.name,
      id: data[0]?.id,
      contents: [
        data[0]?.content1,
        data[0]?.content2,
        data[0]?.content3,
        data[0]?.content4,
      ],
    });
  };

  useEffect(() => {
    getCooperations();
  }, []);

  return (
    <>
      <Header cooperation={cooperation} />
      <Strengths />
      <Services isLoading={isLoading} cooperation={cooperation} />
      <Footer />
    </>
  );
};

export default Cooperation;
