import { motion } from "framer-motion";
import { useEffect } from "react";

const Header = () => {
  const fadeUp = {
    hidden: {
      y: 150,
      opacity: 0,
    },
    visible: () => ({
      y: 0,
      opacity: 1,
    }),
  };

  useEffect(() => {
    document.title = "Документация";
  }, []);

  return (
    <div className="flex flex-col items-center justify-center my-12">
      <motion.p
        className="fs-5rem lg:fs-3rem text-darkGrey my-4 bold"
        transition={{ delay: 0, duration: 1 }}
        initial="hidden"
        whileInView="visible"
        variants={fadeUp}
      >
        Документация
      </motion.p>
      <motion.p
        className="text-darkGrey my-4 fs-5 lg:fs-3 lg:w-60 text-center mb-20"
        transition={{ delay: 0.5, duration: 1 }}
        initial="hidden"
        whileInView="visible"
        variants={fadeUp}
      >
        Презентации, буклеты, технические материалы
      </motion.p>
    </div>
  );
};

export default Header;
