import axios from "axios";
import { baseURL } from "variables/baseURL";

export const getProducts = () => {
  const response = axios.get(baseURL + "products/");
  return response;
};

export const getProduct = async (id) => {
  const response = axios.get(baseURL + `products/${id}`);
  return response;
};
