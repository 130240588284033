import { useMemo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "components/Navbar/Navbar";
import Main from "pages/Main/Main";
import About from "pages/About/About";
import Products from "pages/Products/Products";
import Cooperation from "pages/Cooperation/Cooperation";
import PriceList from "pages/PriceList/PriceList";
import ASKUG from "pages/Askug/ASKUG";
import Documentation from "pages/Documentation/Documentation";
import ParticlesBackground from "components/Particles/Particles";
import { ToastContainer } from "react-toastify";
import "assets/style/styles.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const particlesBG = useMemo(() => <ParticlesBackground />, []);

  return (
    <div className="relative">
      <ToastContainer />
      <div id="tsparticles">{particlesBG}</div>
      <Navbar />

      <Routes>
        <Route path="/main" element={<Main />} />
        <Route path="/about-company" element={<About />} />
        <Route path="/products/*" element={<Products />} />
        <Route path="/cooperation" element={<Cooperation />} />
        <Route path="/price-list" element={<PriceList />} />
        <Route path="/askug" element={<ASKUG />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/" element={<Navigate to="/main" replace />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
