import { useState, useEffect } from "react";
import PriceCard from "components/Cards/PriceCard";
import { getPrices } from "services/pricesService";
import downloadIcon from "assets/icons/download.svg";
import { mediaURL } from "variables/baseURL";

const Prices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState([]);

  const getAllPrices = async () => {
    const res = await getPrices();
    setIsLoading(false);
    setPrices(res.data);
  };

  useEffect(() => {
    getAllPrices();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-darkGray h-20rem flex justify-center items-center">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="py-16 bg-white">
          {prices?.map((counter) => (
            <PriceCard key={counter.id} counter={counter} />
          ))}

          <div className="flex justify-center items-center p-4 my-8">
            <button
              onClick={() => window.open(`${mediaURL}media/ПРАЙС.jpg`)}
              className="bg-orange text-white fs-5 lg:fs-4 px-8 py-2"
            >
              Скачать прайс-лист
            </button>
          </div>

          <>
            <div className="hr w-50 bg-orange my-8" />

            <div className="flex justify-center mb-8">
              <span className="text-orange fs-8">* &nbsp;</span>
              <span className="w-40 lg:w-60 fs-5 lg:fs-4 text-darkGrey">
                При заказе более 10 000 ультразвуковых счетчиков газа на
                модификации GPRS, предлагается автоматизированная система сбора
                данных с использованием облачных технологий на бесплатной
                основе.
              </span>
            </div>

            <div className="hr w-50 bg-orange my-8" />

            <div className="flex justify-center mb-4">
              <span className="text-orange fs-8">** &nbsp;</span>
              <span className="w-40 lg:w-60 fs-5 lg:fs-4 text-darkGrey">
                При заказе более 10 000 ультразвуковых счетчиков газа на
                модификации RF, предлагается автоматизированная система сбора
                данных и дистанционный пульт по сбору данных на бесплатной
                основе (на каждые 1 000 счетчиков один дистанционный пульт для
                считывания данных).
              </span>
            </div>

            <div className="hr w-50 bg-orange my-8" />

            <div className="flex justify-center text-left lg:text-center mb-12">
              <span className="fs-5 lg:fs-4 text-darkGrey">
                При заказе свыше 25 000 ультразвуковых счетчиков газа, стоимость
                договорная!
              </span>
            </div>

            <div className="flex flex-col items-center cursor-pointer p-4 my-4">
              <img className="w-40px" src={downloadIcon} alt="download" />
              <p className="text-orange fs-5 lg:fs-4 fw-600">
                Коммерческое предложение
              </p>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default Prices;
