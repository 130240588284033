import Docs from "./Docs";
import Header from "./Header";
import Footer from "components/Footer/Footer";

const Documentation = () => {
  return (
    <>
      <Header />
      <Docs />
      <Footer />
    </>
  );
};

export default Documentation;
