const ProductInfoCard = ({ product }) => {
  return (
    <div className="my-12">
      <div className="flex items-center justify-center">
        <div className="flex w-50 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {product && product?.col1}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {product && product?.col8}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex justify-end items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            Максимальный измеряемый расход, Qmax (m3/h)
          </span>
        </div>

        <div className="w-100px lg:w-60px mx-12 lg:mx-4" />

        <div className="w-50 flex my-4">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Номинальный измеряемый расход, Qmax (m3/h)
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center">
        <div className="flex w-50 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {product && product?.col2}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {product && product?.col9}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex justify-end items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            Минимальный измеряемый расход, Qmax (m3/h)
          </span>
        </div>

        <div className="w-100px lg:w-60px mx-12 lg:mx-4" />

        <div className="w-50 flex my-4 items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Наибольшее избыточное рабочее давление (кPа)
          </span>
        </div>
      </div>

      {/*  */}

      <div className="flex items-center justify-center">
        <div className="flex w-50 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {product && product?.col3}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {product && product?.col10}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex justify-end items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            Пределы допускаемой относительной погрешности счетчика в диапазоне
            расходов (%):Qmin ≤Q &#8804; 0,1Qnom 0,1Qnom≤Q≤ Qmax
          </span>
        </div>

        <div className="w-100px lg:w-60px mx-12 lg:mx-4" />

        <div className="w-50 flex my-4 items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Допускаемая потеря давления (Pа) при Qmax
          </span>
        </div>
      </div>

      {/*  */}

      <div className="flex items-center justify-center">
        <div className="flex w-50 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {product && product?.col4}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {product && product?.col11}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex justify-end items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            Порог чувствительности счетчика (m3/h)
          </span>
        </div>

        <div className="w-100px lg:w-60px mx-12 lg:mx-4" />

        <div className="w-50 flex my-4 items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Ёмкость индикаторного табло в рабочем режиме (m3)
          </span>
        </div>
      </div>

      {/*  */}

      <div className="flex items-center justify-center">
        <div className="flex w-50 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {product && product?.col5}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {product && product?.col12}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex justify-end items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            Цена наименьшего разряда в рабочем режиме (m3)
          </span>
        </div>

        <div className="w-100px lg:w-60px mx-12 lg:mx-4" />

        <div className="w-50 flex my-4 items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Расчётное избыточное давление газа (Pа)
          </span>
        </div>
      </div>

      {/*  */}

      <div className="flex items-center justify-center">
        <div className="flex w-50 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {product && product?.col6}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {product && product?.col13}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex justify-end items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            Температура, к которой приводится объём газа (°С)
          </span>
        </div>

        <div className="w-100px lg:w-60px mx-12 lg:mx-4" />

        <div className="w-50 flex my-4 items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Масса (kg)
          </span>
        </div>
      </div>

      {/*  */}

      <div className="flex items-center justify-center">
        <div className="flex w-50 justify-end shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-right">
            {product && product?.col6}
          </span>
        </div>

        <div className="w-100px lg:w-60px hr mx-12 lg:mx-4" />

        <div className="flex w-50 p-4 shadow rounded-sm py-4 px-8 lg:px-4">
          <span className="text-orange fs-6 lg:fs-5 md:fs-4 fw-600 text-left">
            {product && product?.col13}
          </span>
        </div>
      </div>

      <div className="flex">
        <div className="w-50 flex justify-end items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 text-right py-4">
            Габаритные размеры (mm) не более
          </span>
        </div>

        <div className="w-100px lg:w-60px mx-12 lg:mx-4" />

        <div className="w-50 flex my-4 items-center">
          <span className="w-70 text-darkGrey fs-5 lg:fs-4 md:fs-3 py-4">
            Срок службы (лет) не менее
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoCard;
