import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProducts } from "services/productsService";
import { motion } from "framer-motion";
import CountersSwiper from "components/Swiper/CountersSwiper";
import { SwiperSlide } from "swiper/react";
import { mediaURL } from "variables/baseURL";

const Counters = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [activeIndex, setactiveIndex] = useState(0);

  const getAllProducts = async () => {
    try {
      const res = await getProducts();
      setIsLoading(false);
      setProducts(res.data);
    } catch (error) {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-darkGray h-20rem flex justify-center items-center">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="flex md:flex-col overflow-hidden">
          <motion.div
            className="flex justify-center items-center w-30 md:w-full sm:absolute relative bg-orange zIndex-10"
            initial={{ x: "-500%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0, duration: 1 }}
          >
            <div className="w-full md:w-10/12 absolute left-50p md:relative md:left-0">
              <CountersSwiper
                setactiveIndex={setactiveIndex}
                children={products?.map((product) => (
                  <SwiperSlide key={product?.id}>
                    <img
                      className="w-full h-full p-8"
                      src={`${mediaURL}${product?.photo}`}
                      alt={product?.id}
                    />
                  </SwiperSlide>
                ))}
              />
            </div>
          </motion.div>

          <motion.div
            className="md:w-full h-full flex justify-end items-center bg-img py-4 sm:px-4 md:px-12"
            initial={{ x: "500%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.7, duration: 1.5 }}
          >
            <div className="w-70 md:w-full md:w-full h-full flex flex-col justify-center">
              <p className="text-darkGrey fs-8 fw-600 my-4">
                {products[activeIndex]?.name}
              </p>

              <p className="whitespace-pre text-darkGrey fs-5 w-70">
                {products[activeIndex]?.description}
              </p>

              <p className="text-orange fs-8 fw-600 my-4 px-4">
                {products[activeIndex]?.price}
              </p>

              <motion.div className="flex flex-wrap sm:justify-center gap-4 my-8">
                <button
                  onClick={() =>
                    navigate(`/products/${products[activeIndex]?.id}`)
                  }
                  className="bg-darkGrey hover:bg-orange fs-5 text-white outline-none px-8 py-2 border-none rounded-sm"
                >
                  Подробнее
                </button>
                <button className="text-darkGrey hover:text-orange fs-5 outline-none px-8 py-2 border-none rounded-sm">
                  Прайс-лист
                </button>
              </motion.div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default Counters;
