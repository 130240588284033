import { useState, useEffect } from "react";
import DocsCard from "components/Cards/DocsCard";
import { getDocs } from "services/docsService";
import { mediaURL } from "variables/baseURL";

const Docs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [docs, setDocs] = useState([]);

  const getAllDocs = async () => {
    try {
      const res = await getDocs();
      setIsLoading(false);
      setDocs(res.data);
    } catch (error) {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getAllDocs();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-darkGray h-20rem flex justify-center items-center">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="py-4 bg-white">
          <div className="flex justify-center flex-wrap container my-16">
            {docs.map((doc) => (
              <DocsCard
                key={doc?.id}
                text={doc?.name}
                file={`${mediaURL}${doc?.file}`}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Docs;
