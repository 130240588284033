import Header from "./Header";
import Footer from "components/Footer/Footer";
import Prices from "./Prices";

const PriceList = () => {
  return (
    <>
      <Header />
      <Prices />
      <Footer />
    </>
  );
};

export default PriceList;
