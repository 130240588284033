import { useState, useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import { Lazy, Navigation, FreeMode, Pagination } from "swiper";
import CustomSwiper from "components/Swiper/CustomSwiper";
import { getCertificates } from "services/certificatesService";
import { mediaURL } from "variables/baseURL";

const Certificates = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [certificates, setCertificates] = useState([]);

  const getAllCertificates = async () => {
    try {
      const res = await getCertificates();
      setIsLoading(false);
      setCertificates(res.data);
    } catch (error) {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getAllCertificates();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-darkGray h-20rem flex justify-center items-center">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="bg-white">
          <div className="flex justify-center items-center container mb-16">
            <CustomSwiper
              slidesPerView={3}
              modules={[Lazy, Navigation, FreeMode, Pagination]}
              pagination={{
                clickable: true,
                bulletActiveClass: "bg-orange opacity-1",
              }}
              freeMode={true}
              children={certificates?.map((certificate) => (
                <SwiperSlide
                  key={certificate.id}
                  className="flex justify-center items-center p-12"
                >
                  <img
                    className="w-full h-full"
                    src={`${mediaURL}${certificate?.photo}`}
                    alt={`certificate_${certificate.id}`}
                  />
                </SwiperSlide>
              ))}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Certificates;
