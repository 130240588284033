import { motion } from "framer-motion";
import { useEffect } from "react";

const Header = ({ cooperation }) => {
  const fadeUp = {
    hidden: {
      y: 150,
      opacity: 0,
    },
    visible: () => ({
      y: 0,
      opacity: 1,
    }),
  };

  useEffect(() => {
    document.title = "Сотрудничество";
  }, []);

  return (
    <div className="flex flex-col items-center justify-center text-center my-8">
      <motion.p
        className="text-darkGrey fw-600 fs-5rem sm:fs-2rem lg:fs-3rem lg:w-full mb-8 p-4"
        transition={{ delay: 0, duration: 1 }}
        initial="hidden"
        whileInView="visible"
        variants={fadeUp}
      >
        Сотрудничество
      </motion.p>

      <motion.p
        className="text-darkGrey fs-5 fs-2rem w-40rem sm:w-90 lg:w-70 mb-16 p-4"
        transition={{ delay: 0.5, duration: 1 }}
        initial="hidden"
        whileInView="visible"
        variants={fadeUp}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: cooperation?.contents?.[0],
          }}
        ></div>
      </motion.p>
    </div>
  );
};

export default Header;
