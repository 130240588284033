export const pages = [
  {
    id: 1,
    path: "/main",
    label: "Главная",
  },
  {
    id: 2,
    path: "/about-company",
    label: "О компании",
  },
  {
    id: 3,
    path: "/products",
    label: "Продукция",
  },
  {
    id: 4,
    path: "/cooperation",
    label: "Сотрудничество",
  },
  {
    id: 5,
    path: "/price-list",
    label: "Прайс-лист",
  },
  {
    id: 6,
    path: "/askug",
    label: "АСКУГ",
  },
  {
    id: 7,
    path: "/documentation",
    label: "Документация",
  },
];
